<script>
export default {
  props: {
    prices: Object,
    y_title: String,
  },
  data() {
    return {
      chart_options: {
        chart: {
          zoomType: 'x'
        },
        title: {
          text: null,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: this.y_title,
            enabled: true,
          },
        },
        credits: {
          enabled: false,
        },

        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                return this.chart.series.some((s) => this === s ? !s.visible : s.visible
                );
              }
            },
          },
        },

        series: Object.keys(this.prices).map((key, index) => {
          return {
            name: key == "SP1" ? "SYS" : key,
            type: "line",
            data: this.prices[key],
            yAxis: 0,
            visible: key == 'SP1' ? true : false,
            tooltip: {
              pointFormat: "{series.name}: {point.y} EUR/MWh",
              valueDecimals: 0,
              xDateFormat: "%b %e %Y %H:%M",
              shared: true,
            },
            marker: {
              radius: 0,
            },
          }
        })
      },
    };
  },
};
</script>

<template lang="pug">
v-highcharts(:options="chart_options")
</template>

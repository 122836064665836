// Components that need to be available in the rails templates
import AccountsForm from './fall_leases/accounts_form.vue'
import Benchmarking from './benchmarking/main.vue'
import ChartsDashboardVolume from './charts/dashboard_volume.vue'
import ChartsElcert from './charts/elcert.vue'
import ChartsMarketPrices from './charts/market_prices.vue'
import ChartsReservoirFill from './charts/reservoir_fill.vue'
import ChartsPortfolioPercentResult from './charts/portfolio/percent_result.vue'
import ChartsPortfolioSortedResult from './charts/portfolio/sorted_result.vue'
import ChartsPortfolioScatter from './charts/portfolio/scatter.vue'
import ChartsPowerPlantGeo from './charts/power_plant/geo.vue'
import ChartsPowerPlantHistory from './charts/power_plant/history.vue'
import ChartsPowerPlantLoad from './charts/power_plant/load.vue'
import ChartsPowerPlantRunoff from './charts/power_plant/runoff.vue'
import ChartsPowerPlantMeteogram from './charts/power_plant/meteogram.vue'
import ChartsMonthlyResults from './charts/monthly_results.vue'
import ChartsPowerPlantSpider from './charts/power_plant/spider.vue'
import ChartsPowerPlantWeatherForecast from './charts/power_plant/weather_forecast.vue'
import ChartsPowerPlantSolarHeatmap from './charts/power_plant/solar_heatmap.vue'
import ChartsPowerPlantSolarKpiMonthly from './charts/power_plant/solar_kpi_monthly.vue'
import ChartsPowerPlantWindKpiMonthly from './charts/power_plant/wind_kpi_monthly.vue'
import ChartsPowerPlantMeasureTheorHourly from './charts/power_plant/measure_theor_hourly.vue'
import ChartsPowerPlantPowerCurve from './charts/power_plant/power_curve.vue'
import CompanyOwners from './company_owners.vue'
import FallLeaseContractsTable from './fall_lease_contracts_table.vue'
import ContractsAdminTable from './contracts_admin_table.vue'
import ContractsEdit from './contracts/edit.vue'
import PowerPlantWindRose from './power_plant_wind_rose.vue'
import PowerPlantWindyMap from './power_plant_windy_map.vue'
import DashboardPowerPlantList from './dashboard_power_plant_list.vue'
import OverviewPowerPlantList from './overview/index.vue'
import OverviewSortSelector from './navigation/overview_sort_selector.vue'
import DataExportForm from './data_export_form.vue'
import DowntimeTable from './downtime_table.vue'
import DowntimePeriods from './downtime_periods/index.vue'
import DynamicProgressCircular from './dynamic_progress_circular.vue'
import ExpandableInfo from './expandable_info.vue'
import FallLeases from './fall_leases/index.vue'
import FallLeasesEdit from './fall_leases/edit.vue'
import HourlyValues from './hourly_values.vue'
import Navigation from './navigation/index.vue'
import NewsArchiveFiltering from './news_items/archive_filtering.vue'
import NewsItemAccessSelection from './news_items/access_selection.vue'
import NewsItemCard from './news_items/card.vue'
import NewsItemIcons from './news_items/icons.vue'
import NewsItems from './news_items/news_items.vue'
import NewsWidget from './news_items/news_widget.vue'
import NvePlantsTable from './nve_plants_table.vue'
import OptionalFields from './optional_fields.vue'
import OverviewMap from './overview_map/index.vue'
import OmOverview from './om_dashboard/overview.vue'
import OmDrilldownCommon from './om_dashboard/drilldown.vue'
import OmRecentAlarms from './om_dashboard/recent_alarms.vue'
import OmSensorMapping from './om_dashboard/elements/sensor_mapping.vue'
import OmTimeScope from './om_dashboard/elements/time_scope.vue'
import Paginate from './paginate.vue'
import PortfolioTable from './portfolio_table.vue'
import PowerPlantEditButton from './power_plants/edit_button.vue'
import PowerPlantsAdminTable from './power_plants/admin_table.vue'
import PowerPlantNav from './power_plant_nav.vue'
import PowerPlantSelection from './power_plant_selection.vue'
import PowerPlantsEditTable from './power_plants/edit_table.vue'
import MarketplaceProducts from './marketplace_products/index.vue'
import SearchableTreeview from './searchable_treeview.vue'
import SimpleDialog from './simple_dialog.vue'
import WorkItems from './work_items/index.vue'
import WorkItemStatistics from './work_items/statistics/index.vue'
import Tooltip from './tooltip.vue'
import UserAccessLevel from './user_access_level.vue'
import UserCard from './common/user_card.vue'
import VDatePickerInput from './v_date_picker_input.vue'
import VHighcharts from './v_highcharts.vue'
import VMultiSelect from './v_multi_select.vue'
import VRadioGroupModified from './v_radio_group_modified.vue'
import VSearchableMultiSelect from './v_searchable_multi_select.vue'
import VSearchableSelect from './v_searchable_select.vue'
import VTimePickerInput from './v_time_picker_input.vue'
import MarkdownEditor from './markdown_editor.vue'
import ChartsBudgetProfile from './charts/budget_profile/results.vue'
import BudgetProfileTable from './budget_profile_table.vue'
import RevenueProfileTable from './revenue_profile_table.vue'
import PriceProfileTable from './price_profile_table.vue'
import ChartsAgeDistribution from './charts/portfolio/age_distribution.vue'
import ChartsPowerPlantsYearlyLoadHour from './charts/portfolio/power_plants_yearly_load_hour.vue'
import FeatureRequestFiltering from './feature_requests/filtering.vue'
import FeatureRequestTable from './feature_requests/table.vue'
import ChartsPortfolioHourlyResult from './charts/portfolio/hourly_result.vue'
import ChartsPortfolioProductionRevenue from './charts/portfolio/production_revenue.vue'
import ChartsAveragePrices from './charts/average_prices.vue'
import ChartsPriceForwardCurveTable from './charts/market_outlook/price_forward_curve_table.vue'
import NavSelector from './navigation/nav_selector.vue'
import ChartsMarketOutlookSpotPrices from './charts/market_outlook/spot_prices.vue'
import ChartsMarketOutlookPfcPrices from './charts/market_outlook/pfc_prices.vue'
import ChartsMarketOutlookDuration from './charts/market_outlook/duration.vue'
import ChartsMarketOutlookBoxSimulations from './charts/market_outlook/box_simulations.vue'
import ChartsMarketOutlookForwards from './charts/market_outlook/forwards.vue'
import FollowUp from './follow_up/follow_up.vue'
import ResponsiblesIndex from './responsibles/index.vue'
import ResponsiblesShow from './responsibles/show.vue'
import PpaPrices from './ppa_prices/ppa_prices.vue'
import Liquidity from './liquidity/liquidity.vue'
import LiquidityMinMaxExpectedChart from './liquidity/min_max_expected_chart.vue'
import CreateBudgetForecastsDialog from '../components/create_budget_forecasts_dialog.vue'
import Responsible from './responsibles/responsible.vue'
import VMonthRangePicker from './v_month_range_picker.vue'
import TimeSeriesImportForm from './time_series_import_form.vue'
import ProductionSimulationPlanCharts from './charts/production_simulation_plan/charts.vue'
import ProductionSimulationPlanQueryButton from './production_simulation_plan/query_button.vue'
import PageHitsTable from './page_hits/page_hits_table.vue'
import SolarInverterTypes from './power_plants/form/solar/inverter_types.vue'
import SolarModuleTypes from './power_plants/form/solar/module_types.vue'
import AdminUserTable from './admin_user_table.vue'
import CommercialScada from './commercial_scada/commercial_scada.vue'
import YearMonthNav from './navigation/year_month_nav.vue'
import LoginForm from './navigation/login_form.vue'
import ResetPasswordForm from './navigation/reset_password_form.vue'
import FallLeaseLandownerInfo from './power_plants/fall_lease_landowner_info.vue'
import WindTurbineTypes from './power_plants/form/wind/turbine_types.vue'
import WindPowerCurves from './power_plants/form/wind/power_curves.vue'
import WindEventMappings from './power_plants/form/wind/event_mappings.vue'

export default {
  AccountsForm,
  Benchmarking,
  AdminUserTable,
  ChartsAveragePrices,
  ChartsPriceForwardCurveTable,
  ChartsDashboardVolume,
  ChartsElcert,
  ChartsMarketPrices,
  ChartsReservoirFill,
  ChartsPortfolioPercentResult,
  ChartsPortfolioSortedResult,
  ChartsPortfolioScatter,
  ChartsPowerPlantGeo,
  ChartsPowerPlantHistory,
  ChartsPowerPlantLoad,
  ChartsPowerPlantRunoff,
  ChartsPowerPlantMeteogram,
  ChartsMonthlyResults,
  ChartsPowerPlantSpider,
  ChartsPowerPlantWeatherForecast,
  ChartsPowerPlantSolarHeatmap,
  ChartsPowerPlantSolarKpiMonthly,
  ChartsPowerPlantWindKpiMonthly,
  ChartsPowerPlantMeasureTheorHourly,
  ChartsPowerPlantPowerCurve,
  CompanyOwners,
  FallLeaseContractsTable,
  ContractsAdminTable,
  ContractsEdit,
  PowerPlantWindRose,
  PowerPlantWindyMap,
  DashboardPowerPlantList,
  DataExportForm,
  DowntimeTable,
  DowntimePeriods,
  DynamicProgressCircular,
  ExpandableInfo,
  FallLeases,
  FallLeasesEdit,
  HourlyValues,
  Navigation,
  NewsArchiveFiltering,
  NewsItemAccessSelection,
  NewsItemCard,
  NewsItemIcons,
  NewsItems,
  NewsWidget,
  NvePlantsTable,
  OptionalFields,
  OverviewMap,
  OmOverview,
  OmDrilldownCommon,
  OmRecentAlarms,
  OmSensorMapping,
  OmTimeScope,
  OverviewPowerPlantList,
  OverviewSortSelector,
  Paginate,
  PortfolioTable,
  PowerPlantEditButton,
  PowerPlantNav,
  PowerPlantSelection,
  PowerPlantsAdminTable,
  PowerPlantsEditTable,
  MarketplaceProducts,
  SearchableTreeview,
  SimpleDialog,
  WorkItems,
  WorkItemStatistics,
  Tooltip,
  UserAccessLevel,
  UserCard,
  VDatePickerInput,
  VHighcharts,
  VMultiSelect,
  VRadioGroupModified,
  VSearchableMultiSelect,
  VSearchableSelect,
  VTimePickerInput,
  MarkdownEditor,
  ChartsBudgetProfile,
  BudgetProfileTable,
  RevenueProfileTable,
  PriceProfileTable,
  ChartsAgeDistribution,
  ChartsPowerPlantsYearlyLoadHour,
  FeatureRequestFiltering,
  FeatureRequestTable,
  ChartsPortfolioProductionRevenue,
  ChartsPortfolioHourlyResult,
  NavSelector,
  ChartsMarketOutlookSpotPrices,
  ChartsMarketOutlookPfcPrices,
  ChartsMarketOutlookDuration,
  ChartsMarketOutlookBoxSimulations,
  ChartsMarketOutlookForwards,
  FollowUp,
  ResponsiblesIndex,
  ResponsiblesShow,
  PpaPrices,
  Liquidity,
  LiquidityMinMaxExpectedChart,
  CreateBudgetForecastsDialog,
  Responsible,
  VMonthRangePicker,
  ProductionSimulationPlanCharts,
  TimeSeriesImportForm,
  ProductionSimulationPlanQueryButton,
  PageHitsTable,
  SolarInverterTypes,
  SolarModuleTypes,
  CommercialScada,
  YearMonthNav,
  LoginForm,
  ResetPasswordForm,
  FallLeaseLandownerInfo,
  WindTurbineTypes,
  WindPowerCurves,
  WindEventMappings
}
